.leaflet-control-attribution{
    display: none;
}

.leaflet-control-container >
.leaflet-top,
.leaflet-right {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
}
.leaflet-control-container >
.leaflet-top {
    top: 70px;
}

.space-children-horizontal > * {
    margin-left: 0.5em !important;
    margin-right: 0.5em !important;
}
.space-children-horizontal > *:first-child {
    margin-left: 0 !important;
}
.space-children-horizontal > *:last-child {
    margin-right: 0 !important;
}

.leaflet-control-layers-toggle {
    background-color: var(--primary-color);
    width: 37px !important;
    height: 37px !important;
    background-size: 23px 23px !important;
}

.leaflet-div-icon {
    background: none !important;
    border: none !important;
}

.leaflet-control-zoom-in {
    color: white !important;
    background-color: var(--primary-color) !important;
    padding: 3.5px !important;
}

.leaflet-control-zoom-out {
    color: white !important;
    background-color: var(--primary-color) !important;
    padding: 3.5px !important;
}